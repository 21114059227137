import React, { ReactElement, useEffect } from 'react';
import DicomCardsList from '../components/DicomCardsList/DicomCardsList';
import Section from '../components/Section/Section';
import useDicom from '../shared/hooks/useDicom';
import DicomCardsListLoading from '../components/DicomCardsList/DicomCardsListLoading';

const SharedWithMePage = (): ReactElement => {
  const { userDicoms, fetchUserDicoms, fetchLoading } = useDicom();

  useEffect(() => {
    fetchUserDicoms({ sharedWithMe: true });
  }, [fetchUserDicoms]);

  return (
    <Section>
      {fetchLoading && <DicomCardsListLoading />}
      {userDicoms && !fetchLoading && <DicomCardsList dicomFiles={userDicoms} />}
      {!fetchLoading && userDicoms?.length === 0 && 'No studies found'}
    </Section>
  );
};

export default SharedWithMePage;
