import React, { ReactElement, ReactNode } from 'react';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import { ReactComponent as BrandName } from '../../assets/brandName.svg';
import styles from './AuthPageWrapper.module.css';

const AuthPageWrapper = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.sidebar}>
        <div className={styles.inner}>
          <div className={styles.logo}>
            <BrandMark width={96} height='100%' />
            <BrandName width={147} height='100%' />
          </div>
          <p>Easy medical image sharing</p>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AuthPageWrapper;
