import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { ReactComponent as UploadIcon } from '../../assets/upload.svg';
import styles from './FileDrop.module.css';
import FileItem from './FileItem';
import messages from '../../shared/staticText/messages';

interface FileDropProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  uploadProgress: number;
  loading: boolean;
  disabled: boolean;
}

const validateFile = (file: File): string | null => {
  const validExtensions = ['.dcm', '.DCM'];
  const fileSizeLimit = 40 * 1024 * 1024;
  const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
  const isFileTypeValid = validExtensions.includes(fileExtension);
  const isFileSizeValid = file.size <= fileSizeLimit;
  if (!isFileTypeValid) {
    return messages.invalidFiletype;
  }
  if (!isFileSizeValid) {
    return messages.maxFileSize;
  }
  return null;
};

const FileDrop = ({ file, setFile, uploadProgress, loading, disabled }: FileDropProps) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const fileError = validateFile(file);
      if (!fileError) {
        setFile(file);
      } else {
        toast.error(fileError);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileError = validateFile(file);
      if (!fileError) {
        setFile(file);
      } else {
        toast.error(fileError);
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const removeFile = () => {
    if (loading) return;
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <div
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={cx(
          styles.root,
          dragging && styles.dragging,
          file && styles.fileAdded,
          disabled && styles.disabled,
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <UploadIcon width={108} height='100%' />
            <p>
              <span onClick={handleClick}>Click to upload</span> or drag and drop to upload medical
              image
            </p>
            <small>Allowed files: .dcm | Max file size: 40mb</small>
          </div>
        </div>
        <input
          type='file'
          accept='.dcm'
          onChange={handleChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>
      {file && (
        <div className={styles.fileList}>
          <FileItem
            progress={uploadProgress}
            file={file}
            onRemove={removeFile}
            removeDisabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default FileDrop;
