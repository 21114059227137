import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import { ReactComponent as BrandName } from '../../assets/brandName.svg';
import styles from './Header.module.css';
import Menu from '../Menu/Menu';
import Button from '../Button/Button';
import UserMenu from '../UserMenu/UserMenu';
import { useUserContext } from '../../shared/context/user.context';
import { UserRoles } from '../../shared/enum/userRoles';
import { Link } from 'react-router-dom';
import config from '../../config';

const Header = (): ReactElement => {
  const { userData, user } = useUserContext();

  return (
    <header className={styles.root}>
      <div className={cx('container', styles.inner)}>
        <Link to={config.routes.home} className={styles.logo}>
          <BrandMark width={38} height='100%' className={styles.brandMark} />
          <div className={styles.brandWrapper}>
            <div className={styles.brandName}>
              <BrandName width={90} height='100%' />
            </div>
            {user?.roleId === UserRoles.PROVIDER && userData?.hospital && (
              <div className={styles.medicalUnit}>{userData?.hospital}</div>
            )}
          </div>
        </Link>
        <div className={styles.menu}>
          <Menu />
          {userData?.role === UserRoles.PATIENT && (
            <Button size='large' style='navy'>
              Request record
            </Button>
          )}
          <UserMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
