import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { UserInterface } from '../models/userType';
import { DecodedToken } from '../models/authType';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

interface UserContextType {
  user: DecodedToken | undefined;
  setUser: (value: DecodedToken) => void;
  userData: UserInterface | undefined;
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  logOut: () => void;
  initLoading: boolean;
}

const defaultValue: UserContextType = {
  user: undefined,
  setUser: () => {},
  userData: undefined,
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  logOut: () => {},
  initLoading: true,
};
export const UserContext = React.createContext<UserContextType>(defaultValue);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [user, setUser] = useState<DecodedToken>();
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  const [userData] = useState<UserInterface | undefined>({
    hospital: 'Lenox Hill Hospital, Northwell',
  });

  const logOut = useCallback(() => {
    localStorage.removeItem('jwtToken');
    setUser(undefined);
    navigate(config.routes.signIn);
  }, [navigate]);

  useEffect(() => {
    const checkIfLoggedIn = () => {
      try {
        const accessToken = localStorage.getItem('jwtToken');
        if (typeof accessToken === 'string' && accessToken !== '') {
          setInitLoading(true);
          const decodedToken: DecodedToken = jwtDecode(accessToken);
          if (!decodedToken?.id && !decodedToken.roleId) {
            throw new Error();
          }
          setUser({ id: decodedToken.id, roleId: decodedToken.roleId });
          setIsLoggedIn(true);
        }
        setInitLoading(false);
      } catch (error) {
        setIsLoggedIn(false);
        setInitLoading(false);
        localStorage.removeItem('jwtToken');
        setUser(undefined);
      }
    };
    checkIfLoggedIn();
  }, []);

  const contextValues: UserContextType = useMemo(
    () => ({ user, setUser, userData, isLoggedIn, setIsLoggedIn, logOut, initLoading }),
    [user, setUser, userData, isLoggedIn, setIsLoggedIn, logOut, initLoading],
  );

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
