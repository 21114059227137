import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';

import styles from './AddStudy.module.css';
import SectionDouble from '../SectionDouble/SectionDouble';
import Button from '../Button/Button';
import BackButton from '../Button/BackButton';
import InputField from '../Form/InputField';
// import FileDrop from './FileDrop';

type AddStudyProps = {
  onFinish?: () => void;
  onDelete?: () => void;
};

const AddStudy = ({ onFinish, onDelete }: AddStudyProps): ReactElement => {
  const onSubmit = () => {
    console.log('aici');
  };

  return (
    <SectionDouble
      leftSide={
        <div className={styles.rootLeft}>
          <BackButton onClick={onDelete} />
          <div className={styles.form}>
            <Formik initialValues={{}} onSubmit={onSubmit}>
              <Form className={styles.form}>
                <InputField name='studyName' type='text' label='Study name' required />
                <InputField name='studyName' type='date' label='Date' />
              </Form>
            </Formik>
          </div>
          <div className={styles.buttons}>
            <Button style='naked' fullWidth size='large' onClick={onDelete}>
              Delete
            </Button>
            <Button onClick={onFinish} style='navy' fullWidth size='large'>
              Save
            </Button>
          </div>
        </div>
      }
      rightSide={<div>{/* <FileDrop /> */}</div>}
    />
  );
};

export default AddStudy;
