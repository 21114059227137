import { AxiosProgressEvent } from 'axios';
import { axiosInstance } from '../../api/AxiosInstance';
import { Dicom, DicomData, DicomPatch } from '../models/dicom';
import config from '../../config';

interface DicomAddFormInterface {
  email: string;
  ssn: string;
  name: string;
  dicomData: DicomData;
}

export const uploadDicom = async (
  values: DicomAddFormInterface,
  file: File,
  onUploadProgress: React.Dispatch<React.SetStateAction<number>>,
): Promise<Dicom> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('email', values.email);
  formData.append('ssn', values.ssn);
  formData.append('name', values.name);
  Object.keys(values.dicomData).forEach((key) => {
    const value = values.dicomData[key as keyof DicomData];
    if (typeof value === 'string') {
      formData.append(`dicomData[${key}]`, value);
    } else {
      formData.append(`dicomData[${key}]`, JSON.stringify(value));
    }
  });

  const config = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const total = progressEvent.total || 1;
      const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
      onUploadProgress(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axiosInstance.post('/dicom', formData, config);

  return response.data;
};

export const updateDicom = async (values: DicomPatch, id: number): Promise<Dicom> => {
  const response = await axiosInstance.patch(config.api.dicomId.replace(':id', String(id)), {
    data: values,
  });
  return response.data;
};

export const getUserDicoms = async (): Promise<Dicom[]> => {
  const response = await axiosInstance.get(config.api.dicom);
  return response.data;
};

export const getSharedWithMeDicoms = async (): Promise<Dicom[]> => {
  const response = await axiosInstance.get(config.api.dicomSharedWithMe);
  return response.data;
};

export const getDicomByid = async (id: string): Promise<Dicom> => {
  const response = await axiosInstance.get(config.api.dicomId.replace(':id', id));
  return response.data;
};

export const postDicomToIpfs = async (id: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdIpfs.replace(':id', id));
  return response.data;
};

export const mintNft = async (id: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdMint.replace(':id', id));
  return response.data;
};

export const shareStudy = async (id: string, email: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdShare.replace(':id', id), { email });
  return response.data;
};

export const revokesShareStudy = async (id: string, email: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdRevoke.replace(':id', id), { email });
  return response.data;
};

export const downloadDicomFileById = async (id: string) => {
  const response = await axiosInstance.get(config.api.dicomIdDownload.replace(':id', id), {
    responseType: 'blob',
  });
  return response.data;
};
