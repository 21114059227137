import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import styles from './DicomCard.module.css';
import Button from '../Button/Button';

const DicomCardLoading = ({ patientView }: { patientView?: boolean }): ReactElement => {
  const infoRows: { label: string; value: string }[] = [
    { label: 'Taken on', value: 'sdfsdf' },
    { label: 'Taken in', value: '768768' },
    { label: 'Taken by', value: 'kj2hb3kjn32' },
  ];

  const patientInfoRows: { label: string; value: string }[] = [
    { label: 'Gender', value: 'sdfsdf' },
    { label: 'Age', value: '768768' },
    { label: 'DOB', value: 'kj2hb3kjn32' },
  ];

  return (
    <div className={cx(styles.root, styles.loadingGhost)}>
      <div className={styles.name}>
        <div className={styles.image}>
          <BrandMark width={38} height={'100%'} />
        </div>
        <h2>Title test</h2>
      </div>
      {!patientView && patientInfoRows.length > 0 && (
        <div className={styles.infoRows}>
          {patientInfoRows.map((item) => (
            <div className={styles.infoRow} key={item.label}>
              <div className={styles.label}>{item.label}:</div>
              <div className={styles.value}>{item.value}</div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.infoRows}>
        {infoRows.map((item) => (
          <div className={styles.infoRow} key={item.label}>
            <div className={styles.label}>{item.label}:</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        ))}
      </div>
      <hr className={cx(styles.separator, styles.absolute)} />
      <div className={cx(styles.buttons, styles.absolute)}>
        <Button fullWidth loadingGhost>
          View
        </Button>
        {patientView && (
          <Button fullWidth style='naked' loadingGhost>
            Share
          </Button>
        )}
      </div>
    </div>
  );
};

export default DicomCardLoading;
