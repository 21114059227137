import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import styles from './DicomStudy.module.css';
import Button from '../Button/Button';
import { Dicom } from '../../shared/models/dicom';
import dicomLabels from '../../shared/staticText/dicomLabels';
import ShareStudy from '../ShareStudy/ShareStudy';
import useDicomParserViewer from '../../shared/hooks/useDicomParserViewer';
import { downloadDicomFileById } from '../../shared/services/dicom.service';
import messages from '../../shared/staticText/messages';
import DicomImageViewer from '../DicomViewer/DicomViewer';

const DicomStudy = ({
  dicom,
  patientView,
  loadingGhost,
}: {
  dicom?: Dicom;
  patientView?: boolean;
  loadingGhost?: boolean;
}): ReactElement => {
  const { dicomData, setFile, file } = useDicomParserViewer();
  const [viewRawDicom, setViewRawDicom] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string>();

  useEffect(() => {
    const fetchDicomFile = async () => {
      try {
        setLoading(true);
        const dicomBlob = await downloadDicomFileById(String(dicom?.id));
        const dicomFile = new File([dicomBlob], 'dicom.dcm', { type: dicomBlob.type });
        setFile(dicomFile);
        const url = URL.createObjectURL(dicomBlob);
        setDownloadUrl(url);
      } catch (error) {
        toast.error(messages.dicomDownloadError);
      } finally {
        setLoading(false);
      }
    };
    if (dicom?.id) {
      fetchDicomFile();
    }
  }, [dicom?.id, setFile]);

  const toggleRawView = () => {
    setViewRawDicom((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      if (downloadUrl) {
        URL.revokeObjectURL(downloadUrl);
      }
    };
  }, [downloadUrl]);

  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div className={styles.box}>
        <div className={styles.name}>
          <div className={styles.image}>
            <BrandMark width={38} height={'100%'} />
          </div>
          <h2>
            {!loadingGhost && dicom?.data?.dicomStudyDescription}
            {loadingGhost && 'Test dicom title'}
          </h2>
        </div>
        <hr className={styles.separator} />
        <div className={styles.buttons}>
          {patientView && (
            <>
              <Button
                style='naked'
                loadingGhost={loadingGhost}
                modal={
                  dicom?.id
                    ? { title: 'Share study', contents: <ShareStudy id={dicom?.id} /> }
                    : undefined
                }
              >
                Share
              </Button>
              <Button
                style='naked'
                loadingGhost={loadingGhost}
                modal={
                  dicom?.id
                    ? { title: 'Revoke access', contents: <ShareStudy isRevoke id={dicom?.id} /> }
                    : undefined
                }
              >
                Revoke
              </Button>
            </>
          )}
          {(loading || loadingGhost) && (
            <Button style='naked' loadingGhost={true}>
              Download DICOM
            </Button>
          )}
          {downloadUrl && !loading && !loadingGhost && (
            <a href={downloadUrl} download={`dicom-${dicom?.data?.dicomStudyDescription}.dcm`}>
              <Button style='naked'>Download DICOM</Button>
            </a>
          )}
        </div>
        <hr className={styles.separator} />
        <div className={styles.infoRows}>
          {!loadingGhost &&
            dicom?.data &&
            Object.keys(dicom.data).map((key) => {
              if (key === 'patientEmail' || key === 'dicomStudyDescription') return null;
              const value = dicom?.data[key as keyof typeof dicom.data];
              const label = dicomLabels[key as keyof typeof dicomLabels] || key;
              return value ? (
                <div className={styles.infoRow} key={key}>
                  <div className={styles.label}>{label}:</div>
                  <div className={styles.value}>{value}</div>
                </div>
              ) : null;
            })}
          {loadingGhost && !dicom?.data && (
            <>
              {[...Array(5)].map((_, index) => (
                <div className={styles.infoRow} key={index}>
                  <div className={styles.label}>Label test:</div>
                  <div className={styles.value}>Value test </div>
                </div>
              ))}
            </>
          )}
        </div>
        <hr className={styles.separator} />
        <div>
          <Button onClick={toggleRawView} style='naked' loadingGhost={loading || loadingGhost}>
            {viewRawDicom ? 'Hide r' : 'R'}aw dicom data
          </Button>
        </div>
        {dicomData && viewRawDicom && (
          <div className={styles.infoRows}>
            {dicomData.map((data, index) => (
              <div className={styles.infoRow} key={index}>
                <div className={styles.label}>{data.name}:</div>
                <div className={styles.value}>{data.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.box}>
        <DicomImageViewer file={file} loadingGhost={loading || loadingGhost} />
      </div>
    </div>
  );
};

export default DicomStudy;
