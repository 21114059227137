import React, { ReactElement } from 'react';
import styles from './DicomCardsList.module.css';
import { Dicom } from '../../shared/models/dicom';
import DicomCard from '../DicomCard/DicomCard';

const DicomCardsList = ({
  dicomFiles,
  patientView,
}: {
  dicomFiles: Dicom[];
  patientView?: boolean;
}): ReactElement => {
  return (
    <div className={styles.root}>
      {dicomFiles.map((dicom) => (
        <DicomCard dicom={dicom} key={dicom.id} patientView={patientView} />
      ))}
    </div>
  );
};

export default DicomCardsList;
