import React, { ReactElement } from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/arrowLeft.svg';
import styles from './BackButton.module.css';

type ButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

const BackButton = ({ disabled, onClick }: ButtonProps): ReactElement => {
  return (
    <button className={styles.root} disabled={disabled} onClick={onClick}>
      <div className={styles.icon}>
        <ArrowLeft width={24} height='100%' />
      </div>
      <span>Back</span>
    </button>
  );
};

export default BackButton;
