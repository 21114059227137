import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './Tag.module.css';

interface TagProps {
  type: 'new' | 'share' | 'release';
}

const Tag = ({ type }: TagProps): ReactElement => {
  return (
    <div
      className={cx(
        styles.root,
        type === 'new' && styles.new,
        type === 'share' && styles.share,
        type === 'release' && styles.release,
      )}
    >
      {type === 'new' && 'New'}
      {type === 'share' && 'Shared'}
      {type === 'release' && 'Released'}
    </div>
  );
};

export default Tag;
