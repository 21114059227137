import React from 'react';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as Xicon } from '../../assets/xIcon.svg';
import styles from './FileItem.module.css';
import formatFileSize from '../../shared/utils/formatFileSize';

const FileItem = ({
  file,
  onRemove,
  progress,
  removeDisabled,
}: {
  file: File;
  onRemove: () => void;
  progress: number;
  removeDisabled: boolean;
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.icon}>
          <FileIcon width={21.5} height='100%' />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{file.name}</div>
          <div className={styles.size}>{formatFileSize(file.size)}</div>
        </div>
        <div className={styles.remove} onClick={onRemove}>
          {!removeDisabled && <Xicon width={13} height='100%' />}
        </div>
      </div>
      {progress > 0 && (
        <div className={styles.loadingBar}>
          <div className={styles.fullBar}>
            <div className={styles.state} style={{ width: `${progress}%` }} />
          </div>
          <div className={styles.percentage}>{progress}%</div>
        </div>
      )}
    </div>
  );
};

export default FileItem;
