import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styles from './SignIn.module.css';
import config from '../../config';
import useAuth from '../../shared/hooks/useAuth';
import Button from '../../components/Button/Button';
import InputField from '../../components/Form/InputField';

const SignIn = (): ReactElement => {
  const { handleSignIn, errorMessage, loading } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('This field is mandatory'),
    password: Yup.string().required('This field is mandatory'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSignIn}
    >
      <Form className={styles.signInForm}>
        <h2 className={styles.formTitle}>
          Sign in to <span>MediMint</span>
        </h2>
        <p>Provide your password to get access to your MediMint dashboard</p>
        <InputField label='Email' name='email' type='text' required />
        <InputField label='Password' name='password' type='password' required />
        <p className={styles.forgotPassword}>
          Forgot your password?{' '}
          <span>
            <Link to={config.routes.recoverPassword}> Recover</Link>
          </span>
        </p>
        {errorMessage && <p className={styles.erorr}>{errorMessage}</p>}
        <Button style='navy' size='large' type='submit' loading={loading}>
          Sign in
        </Button>
      </Form>
    </Formik>
  );
};

export default SignIn;
