import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DicomImage, NativePixelDecoder } from 'dcmjs-imaging';
import { toast } from 'react-toastify';
import cx from 'classnames';
import styles from './DicomViewer.module.css';
import Button from '../Button/Button';
import messages from '../../shared/staticText/messages';

interface ThumbnailData {
  url: string;
  width: number;
  height: number;
}

const DicomImageViewer = ({
  file,
  loadingGhost,
}: {
  file: File | null;
  loadingGhost?: boolean;
}) => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const [viewAllFrames, setViewAllFrames] = useState<boolean>(false);
  const [inititalizedDecoder, setInititalizedDecoder] = useState<boolean>(false);
  const [thumbnails, setThumbnails] = useState<ThumbnailData[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const initializeDecoder = async () => {
    await NativePixelDecoder.initializeAsync({
      webAssemblyModulePathOrUrl: '/native-pixel-decoder.wasm',
      logNativeDecodersMessages: false,
    });
  };

  const ImageDataToBlob = function (imageData: ImageData) {
    const w = imageData.width;
    const h = imageData.height;
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.putImageData(imageData, 0, 0);

    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  };

  const generateThumbnails = useCallback(
    async (dicomImage: DicomImage) => {
      const newThumbnails: ThumbnailData[] = [];
      for (let i = 0; i < totalFrames; i++) {
        const thumbnailResult = dicomImage.render({ frame: i });
        const imageData = new ImageData(
          new Uint8ClampedArray(thumbnailResult.pixels),
          thumbnailResult.width,
          thumbnailResult.height,
        );

        const blob = await ImageDataToBlob(imageData);
        const url = URL.createObjectURL(blob as Blob);

        newThumbnails.push({
          url: url,
          width: thumbnailResult.width,
          height: thumbnailResult.height,
        });
      }
      setThumbnails(newThumbnails);
    },
    [totalFrames],
  );

  const processDICOMFile = useCallback(
    async (file: File) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target && e.target.result) {
          const dicomImage = new DicomImage(e.target.result as ArrayBuffer);
          const renderingResult = dicomImage.render({
            frame: currentFrame,
          });

          const numFrames = dicomImage.getNumberOfFrames();
          setTotalFrames(numFrames);
          generateThumbnails(dicomImage);

          if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
              const imageData = new ImageData(
                new Uint8ClampedArray(renderingResult.pixels),
                renderingResult.width,
                renderingResult.height,
              );
              canvasRef.current.width = renderingResult.width;
              canvasRef.current.height = renderingResult.height;
              context.putImageData(imageData, 0, 0);
            }
          }
        }
      };
      reader.readAsArrayBuffer(file);
    },
    [currentFrame, generateThumbnails],
  );

  const goToNextFrame = () => {
    setCurrentFrame((prevFrame) => (prevFrame + 1) % totalFrames);
  };

  const goToPreviousFrame = () => {
    setCurrentFrame((prevFrame) => (prevFrame - 1 + totalFrames) % totalFrames);
  };

  useEffect(() => {
    if (file && inititalizedDecoder) {
      processDICOMFile(file);
    }
  }, [file, processDICOMFile, inititalizedDecoder]);

  useEffect(() => {
    initializeDecoder()
      .then(() => setInititalizedDecoder(true))
      .catch(() => toast.error(messages.dicomImageDicoderInitializeFailed));
  }, []);

  const toggleThumbnailsView = () => {
    setViewAllFrames((prev) => !prev);
  };

  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <canvas ref={canvasRef} className={styles.canvas}></canvas>
      <div className={styles.twoButtons}>
        <Button
          onClick={goToPreviousFrame}
          fullWidth
          style='naked'
          size='large'
          loadingGhost={loadingGhost}
          disabled={thumbnails.length <= 1}
        >
          Prev frame
        </Button>
        <Button
          onClick={goToNextFrame}
          fullWidth
          style='naked'
          size='large'
          loadingGhost={loadingGhost}
          disabled={thumbnails.length <= 1}
        >
          Next frame
        </Button>
      </div>
      {loadingGhost && (
        <div>
          <Button style='naked' loadingGhost>
            View thumbnails
          </Button>
        </div>
      )}
      {thumbnails.length > 1 && !loadingGhost && (
        <>
          <div>
            <Button style='naked' onClick={toggleThumbnailsView}>
              {viewAllFrames ? 'Hide' : 'View'} thumbnails
            </Button>
          </div>
          {viewAllFrames && (
            <div className={styles.thumbnails}>
              {thumbnails.map((thumbnail, index) => (
                <img
                  key={index}
                  src={thumbnail.url}
                  alt={`Frame ${index}`}
                  className={styles.frame}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DicomImageViewer;
