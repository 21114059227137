import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AxiosError } from 'axios';
import { useUserContext } from '../context/user.context';
import { DecodedToken } from '../models/authType';
import { UserRoles } from '../enum/userRoles';
import config from '../../config';
import { login } from '../services/auth.service';
import { AuthError } from '../enum/authErrors';

interface UserDicomHook {
  loading: boolean;
  handleSignIn: (values: AuthValues) => Promise<void>;
  errorMessage: string | undefined;
}

interface AuthValues {
  email: string;
  password: string;
}

const useAuth = (): UserDicomHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();
  const { setUser, setIsLoggedIn } = useUserContext();
  const setSessionToken = (token: string) => {
    try {
      localStorage.setItem('jwtToken', token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignIn = async (values: AuthValues) => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      const response = await login(values);
      setIsLoggedIn(true);
      const token = response.data.accessToken;
      setSessionToken(token);
      const decodedToken: DecodedToken = jwtDecode(token);
      setUser({ id: decodedToken.id, roleId: decodedToken.roleId });
      if (decodedToken.roleId === UserRoles.PATIENT) {
        navigate(config.routes.myMedicalRecords);
        return;
      }
      navigate(config.routes.home);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === AuthError.WRONG_CREDENTIALS) {
          setErrorMessage('Wrong credentials');
        } else {
          setErrorMessage('Unexpected error');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSignIn,
    errorMessage,
  };
};

export default useAuth;
