import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../components/Section/Section';
import DicomStudy from '../../components/DicomStudy/DicomStudy';
import { Dicom } from '../../shared/models/dicom';
import useDicom from '../../shared/hooks/useDicom';
import NotFound from '../404/notFound';
import { useUserContext } from '../../shared/context/user.context';

const StudyPage = (): ReactElement => {
  const { id } = useParams();
  const [dicom, setDicom] = useState<Dicom>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const { fetchDicomById, fetchLoading } = useDicom();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchDicom = async () => {
      setNotFound(false);
      if (id) {
        const dicomData = await fetchDicomById(id);
        if (dicomData) setDicom(dicomData);
        if (!dicomData) setNotFound(true);
      }
    };
    fetchDicom();
  }, [id, fetchDicomById]);

  const patientView = useMemo((): boolean => {
    if (user?.id === dicom?.userId) return true;
    return false;
  }, [dicom?.userId, user?.id]);

  return (
    <>
      <Section>
        {notFound && <NotFound />}
        {!notFound && (
          <DicomStudy loadingGhost={fetchLoading} patientView={patientView} dicom={dicom} />
        )}
      </Section>
    </>
  );
};

export default StudyPage;
