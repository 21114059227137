import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import SectionDouble from '../../components/SectionDouble/SectionDouble';
import styles from './releaseRecord.module.css';
import InputField from '../../components/Form/InputField';
import Button from '../../components/Button/Button';
import AddStudy from '../../components/AddStudy/AddStudy';
import FileDrop from '../../components/AddStudy/FileDrop';
import {
  DicomAddFormInterface,
  DicomData,
  DicomPatch,
  DicomProps,
} from '../../shared/models/dicom';
import useDicom from '../../shared/hooks/useDicom';
import dicomLabels from '../../shared/staticText/dicomLabels';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { DicomStatus } from '../../shared/enum/dicom';
import { NftStatus } from '../../shared/enum/enum';
import convertDicomStringDate from '../../shared/utils/convertDicomStringDate';
import messages from '../../shared/staticText/messages';

// const Instructions = (): ReactElement => (
//   <div className={styles.instructions}>
//     <h3 className={styles.instructionsTitle}>Instructions/infos</h3>
//     <ul>
//       <li>
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc dolor. Vestibulum
//         elementum aliquet mattis. Nunc ac ex condimentum purus laoreet tempus sagittis at augue.
//         Morbi iaculis et dolor eu eleifend.
//       </li>
//       <li>
//         Nunc vitae felis sed augue malesuada egestas eget et urna. Cras elementum finibus orci eu
//         sollicitudin. Praesent aliquet pretium nibh, sit amet dapibus metus porttitor vitae.
//       </li>
//       <li>
//         Nam vestibulum purus leo, vehicula tempus libero gravida at. Sed id viverra massa. Nulla
//         venenatis semper velit rhoncus varius.
//       </li>
//       <li>
//         In hac habitasse platea dictumst. Fusce convallis ac nisi sed elementum. Proin suscipit
//         bibendum odio eu maximus. Nunc fringilla lacinia laoreet. Aliquam sed varius orci.
//       </li>
//     </ul>
//   </div>
// );

const UpdateDicomFields = ({ dicomData }: { dicomData: DicomPatch }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (dicomData) {
      setFieldValue('patientName', dicomData.patientName);
      setFieldValue(
        'patientDOB',
        dicomData.patientDOB && convertDicomStringDate(dicomData.patientDOB, true),
      );
      setFieldValue('providerNpi', dicomData.providerNpi);
      setFieldValue('providerName', dicomData.providerName);
      setFieldValue('referringPhysicianName', dicomData.referringPhysicianName);
      setFieldValue('performingPhysicianName', dicomData.performingPhysicianName);
      setFieldValue('physicianEmail', dicomData.physicianEmail);
      setFieldValue('radiologistName', dicomData.radiologistName);
      setFieldValue('radiologistEmail', dicomData.radiologistEmail);
    }
  }, [dicomData, setFieldValue]);

  return null;
};

const ReleaseRecordPage = (): ReactElement => {
  const [formValues] = useState<DicomAddFormInterface & DicomData>({
    patientName: '',
    patientEmail: '',
    ssn: '',
    patientDOB: '',
    providerNpi: '',
    providerName: '',
    referringPhysicianName: '',
    performingPhysicianName: '',
    physicianEmail: '',
    radiologistName: '',
    radiologistEmail: '',
    dicomStudyDescription: '',
    dicomStudyDate: '',
    dicomAccessionId: '',
    dicomSeriesId: '',
    dicomSeriesModality: '',
    dicomSeriesDescription: '',
    dicomSeriesNumber: '',
    dicomBodyPartExamined: '',
    dicomSOPInstanceId: '',
  });

  const [showAddStudy, setShowAddStudy] = useState<boolean>(false);
  const [uploadedDicom, setUploadedDicom] = useState<DicomProps>();

  const {
    handleDicomForm,
    formLoading,
    uploadProgress,
    dicomData,
    setFile,
    file,
    pinDicomToIpfs,
    mintDicomNft,
  } = useDicom();

  const onSubmit = async (
    values: DicomAddFormInterface,
    { resetForm }: FormikHelpers<DicomAddFormInterface>,
  ) => {
    if (!file) return;
    const uploadedDicom = await handleDicomForm(values, file);
    if (uploadedDicom) {
      resetForm();
      setUploadedDicom(uploadedDicom);
    }
  };

  const onPinToIpfs = async () => {
    if (!uploadedDicom) return;
    const response = await pinDicomToIpfs(String(uploadedDicom.id));
    if (response) {
      setUploadedDicom(response);
    }
  };

  const onMintNft = async () => {
    if (!uploadedDicom) return;
    const response = await mintDicomNft(String(uploadedDicom.id));
    if (response) {
      setUploadedDicom(response);
    }
  };

  const onAddStudy = () => {
    toggleAddStudy();
  };

  const onResetProcess = () => {
    setUploadedDicom(undefined);
  };

  const toggleAddStudy = () => {
    setShowAddStudy((prevValue) => !prevValue);
  };

  const validationSchema = Yup.object({
    patientName: Yup.string().required(messages.requiredField),
    patientEmail: Yup.string().email(messages.invalidEmail).required(messages.requiredField),
    ssn: Yup.string().required(messages.requiredField),
    patientDOB: Yup.date().required(messages.requiredField),
    providerName: Yup.string().required(messages.requiredField),
    dicomStudyDate: Yup.date().required(messages.requiredField),
    performingPhysicianName: Yup.string().required(messages.requiredField),
    dicomStudyDescription: Yup.string().required(messages.requiredField),
    patientGender: Yup.string().oneOf(['Male', 'Female', 'Other'], messages.requiredField),
  });

  if (showAddStudy) {
    return <AddStudy onFinish={onAddStudy} onDelete={toggleAddStudy} />;
  }

  return (
    <SectionDouble
      leftSide={
        <div>
          <div className={styles.title}>
            {uploadedDicom
              ? 'Finish uploading process of study data'
              : 'Who would you like to send medical records to?'}
          </div>
          {uploadedDicom && (
            <div className={styles.progress}>
              <div className={styles.step}>
                <div className={cx(styles.stepStatus, styles.finished)}>
                  <div className={styles.stepIcon}>
                    <CheckIcon width={20} height='100%' />
                  </div>
                </div>
                <div className={styles.stepInfo}>
                  <div>
                    <strong>Uploaded data to S3</strong>
                    <p>Upload DICOM file to S3 storage and encrypted data in the database.</p>
                  </div>
                </div>
              </div>
              <div className={styles.step}>
                <div
                  className={cx(
                    styles.stepStatus,
                    formLoading &&
                      uploadedDicom.status === DicomStatus.UPLOADED_T0_S3 &&
                      styles.loading,
                    uploadedDicom.status === DicomStatus.UPLOADED_T0_IPFS && styles.finished,
                  )}
                >
                  <div className={styles.stepIcon}>
                    {uploadedDicom.status === DicomStatus.UPLOADED_T0_IPFS ? (
                      <CheckIcon width={20} height='100%' />
                    ) : (
                      '2'
                    )}
                  </div>
                </div>
                <div className={styles.stepInfo}>
                  <div>
                    <strong>Pin to IPFS</strong>
                    <p>Push data to IPFS</p>
                    {uploadedDicom.nft?.ipfsHash && (
                      <p className={styles.hash}>IPFS hash: {uploadedDicom.nft?.ipfsHash}</p>
                    )}
                  </div>
                  {uploadedDicom.status === DicomStatus.UPLOADED_T0_S3 && (
                    <Button
                      loading={formLoading && uploadedDicom.status === DicomStatus.UPLOADED_T0_S3}
                      onClick={onPinToIpfs}
                      disabled={formLoading && uploadedDicom.status === DicomStatus.UPLOADED_T0_S3}
                    >
                      Pin to ipfs
                    </Button>
                  )}
                </div>
              </div>
              <div
                className={cx(
                  styles.step,
                  uploadedDicom.status !== DicomStatus.UPLOADED_T0_IPFS && styles.disabled,
                )}
              >
                <div
                  className={cx(
                    styles.stepStatus,
                    formLoading &&
                      uploadedDicom.nft?.status === NftStatus.CREATED &&
                      styles.loading,
                    uploadedDicom.nft?.status === NftStatus.MINTED && styles.finished,
                  )}
                >
                  <div className={styles.stepIcon}>
                    {uploadedDicom.nft?.status === NftStatus.MINTED ? (
                      <CheckIcon width={20} height='100%' />
                    ) : (
                      '3'
                    )}
                  </div>
                </div>
                <div className={styles.stepInfo}>
                  <div>
                    <strong>Mint NFT</strong>
                    <p>Mint data as NFT and release it to the patient.</p>
                  </div>
                  {uploadedDicom.nft?.status !== NftStatus.MINTED && (
                    <Button
                      onClick={onMintNft}
                      loading={formLoading && uploadedDicom.nft?.status === NftStatus.CREATED}
                      disabled={
                        uploadedDicom.status !== DicomStatus.UPLOADED_T0_IPFS ||
                        (formLoading && uploadedDicom.nft?.status === NftStatus.CREATED)
                      }
                    >
                      Mint NFT
                    </Button>
                  )}
                </div>
              </div>
              {uploadedDicom.nft?.status === NftStatus.MINTED && (
                <Button onClick={onResetProcess} size='large'>
                  Release another study
                </Button>
              )}
            </div>
          )}
          {!uploadedDicom && (
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form className={styles.form}>
                  <InputField
                    name='patientName'
                    label={dicomLabels.patientName}
                    type='text'
                    required
                  />
                  <InputField
                    name='patientEmail'
                    label={dicomLabels.patientEmail}
                    type='text'
                    required
                  />
                  <InputField name='ssn' label={dicomLabels.ssn} type='text' required />
                  {dicomData && (
                    <>
                      <UpdateDicomFields dicomData={dicomData} />
                      <InputField
                        name='patientGender'
                        label={dicomLabels.patientGender}
                        type='select'
                        options={['Male', 'Female', 'Other']}
                        required
                      />
                      <InputField
                        name='patientDOB'
                        label={dicomLabels.patientDOB}
                        type='date'
                        required
                      />
                      <InputField
                        name='dicomStudyDescription'
                        label={dicomLabels.studyName}
                        type='text'
                        required
                      />
                      <InputField
                        name='dicomStudyDate'
                        label={dicomLabels.dicomStudyDate}
                        type='date'
                        required
                      />
                      <InputField
                        name='providerName'
                        label={dicomLabels.providerName}
                        type='text'
                        required
                      />
                      <InputField name='providerNpi' label={dicomLabels.providerNpi} type='text' />
                      <InputField
                        name='performingPhysicianName'
                        label={dicomLabels.performingPhysicianName}
                        type='text'
                        required
                      />
                      <InputField
                        name='physicianEmail'
                        label={dicomLabels.physicianEmail}
                        type='email'
                      />
                      <InputField
                        name='referringPhysicianName'
                        label={dicomLabels.referringPhysicianName}
                        type='text'
                      />
                      <InputField
                        name='radiologistName'
                        label={dicomLabels.radiologistName}
                        type='text'
                      />
                      <InputField
                        name='radiologistEmail'
                        label={dicomLabels.radiologistEmail}
                        type='email'
                      />
                      <InputField
                        name='dicomAccessionId'
                        label={dicomLabels.dicomAccessionId}
                        type='text'
                      />
                      <InputField
                        name='dicomSeriesId'
                        label={dicomLabels.dicomSeriesId}
                        type='text'
                      />
                      <InputField
                        name='dicomSeriesModality'
                        label={dicomLabels.dicomSeriesModality}
                        type='text'
                      />
                      <InputField
                        name='dicomSeriesDescription'
                        label={dicomLabels.dicomSeriesDescription}
                        type='text'
                      />
                      <InputField
                        name='dicomSeriesNumber'
                        label={dicomLabels.dicomSeriesNumber}
                        type='text'
                      />
                      <InputField
                        name='dicomBodyPartExamined'
                        label={dicomLabels.dicomBodyPartExamined}
                        type='text'
                      />
                      <InputField
                        name='dicomSOPInstanceId'
                        label={dicomLabels.dicomSOPInstanceId}
                        type='text'
                      />
                    </>
                  )}
                  {Object.keys(errors).length > 0 && Object.keys(touched).length > 5 && (
                    <div className={styles.formError}>Some fields have errors.</div>
                  )}
                  <div className={styles.buttons}>
                    <Button style='naked' fullWidth size='large' disabled={formLoading}>
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      style='navy'
                      size='large'
                      fullWidth
                      disabled={!file || formLoading}
                      loading={formLoading}
                    >
                      Release
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      }
      rightSide={
        <FileDrop
          uploadProgress={uploadProgress}
          loading={formLoading}
          file={file}
          setFile={setFile}
          disabled={Boolean(uploadedDicom)}
        />
      }
    />
  );
};

export default ReleaseRecordPage;
