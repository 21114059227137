import React, { ReactElement } from 'react';
import { ActivityInterface } from '../../shared/models/activity';
import ActivityLine from './ActivityLine';
import styles from './RecentActivities.module.css';

const RecentActivities = ({ events }: { events: ActivityInterface[] }): ReactElement => {
  return (
    <div className={styles.root}>
      {events.map((event, index) => (
        <ActivityLine event={event} key={event.id} bottomHr={Boolean(index + 1 < events.length)} />
      ))}
    </div>
  );
};

export default RecentActivities;
