import React, { ReactElement, ReactNode } from 'react';
import { ReactComponent as Xicon } from '../../assets/xIcon.svg';
import styles from './Modal.module.css';

type ModalProps = {
  children: ReactNode;
  title?: string;
  toggleView: () => void;
};

const Modal = ({ children, title, toggleView }: ModalProps): ReactElement => {
  return (
    <>
      <div className={styles.overlay} onClick={toggleView} />
      <div className={styles.box}>
        <div className={styles.topPart}>
          <div className={styles.title}>{title}</div>
          <div className={styles.close}>
            <button onClick={toggleView} className={styles.closeBtn}>
              <Xicon width={17} height='100%' />
            </button>
          </div>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
