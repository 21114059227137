import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Section.module.css';

interface SectionProps {
  children: ReactNode;
  title?: string;
}

const Section = ({ children, title }: SectionProps): ReactElement => {
  return (
    <div className={cx('container', styles.root)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default Section;
