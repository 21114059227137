import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as dicomParser from 'dicom-parser';
import { Dicom, DicomAddFormInterface, DicomData, DicomPatch } from '../models/dicom';
import {
  getDicomByid,
  getSharedWithMeDicoms,
  getUserDicoms,
  mintNft,
  postDicomToIpfs,
  updateDicom,
  uploadDicom,
} from '../services/dicom.service';

interface UserDicomHook {
  fetchLoading: boolean;
  formLoading: boolean;
  handleDicomForm: (
    formValue: DicomAddFormInterface & DicomData,
    file: File,
  ) => Promise<Dicom | void>;
  uploadProgress: number;
  handleDicomPatchForm: (formValue: DicomPatch, id: number) => Promise<Dicom | void>;
  dicomData: DicomPatch | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
  fetchUserDicoms: ({ sharedWithMe }: { sharedWithMe?: boolean }) => void;
  userDicoms: Dicom[] | undefined;
  fetchDicomById: (id: string) => Promise<Dicom | undefined>;
  pinDicomToIpfs: (id: string) => Promise<Dicom | undefined>;
  mintDicomNft: (id: string) => Promise<Dicom | undefined>;
}

interface DicomDataSet {
  string: (tag: string) => string | undefined;
}

const useDicom = (): UserDicomHook => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [dicomData, setDicomData] = useState<DicomData>();
  const [file, setFile] = useState<File | null>(null);
  const [userDicoms, setUserDicoms] = useState<Dicom[]>();

  const fetchUserDicoms = useCallback(async ({ sharedWithMe }: { sharedWithMe?: boolean }) => {
    try {
      setFetchLoading(true);
      const dicoms = sharedWithMe ? await getSharedWithMeDicoms() : await getUserDicoms();
      setUserDicoms(dicoms);
    } catch (error) {
      toast.error('An error occurred during fetch.');
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  }, []);

  const fetchDicomById = useCallback(async (id: string): Promise<Dicom | undefined> => {
    try {
      setFetchLoading(true);
      return await getDicomByid(id);
    } catch (error) {
      console.log(error);
      return undefined;
    } finally {
      setFetchLoading(false);
    }
  }, []);

  const pinDicomToIpfs = useCallback(async (id: string): Promise<Dicom | undefined> => {
    try {
      setFormLoading(true);
      return await postDicomToIpfs(id);
    } catch (error) {
      console.log(error);
      toast.error('An error occurred during pushing to ipfs.');
      return undefined;
    } finally {
      setFormLoading(false);
    }
  }, []);

  const mintDicomNft = useCallback(async (id: string): Promise<Dicom | undefined> => {
    try {
      setFormLoading(true);
      return await mintNft(id);
    } catch (error) {
      console.log(error);
      toast.error('An error occurred during mint process.');
      return undefined;
    } finally {
      setFormLoading(false);
    }
  }, []);

  const handleDicomForm = useCallback(
    async (formValue: DicomAddFormInterface & DicomData, file: File): Promise<Dicom | void> => {
      if (!file) {
        return;
      }
      try {
        setUploadProgress(0);
        setFormLoading(true);
        const { patientName, patientEmail, ssn, ...dicomData } = formValue;
        const uploadedDicom = await uploadDicom(
          { name: patientName, email: patientEmail, ssn: ssn, dicomData: dicomData },
          file,
          setUploadProgress,
        );
        toast.success('Upload successful!');
        setUploadProgress(0);
        setFile(null);
        return uploadedDicom;
      } catch (error) {
        console.log(error);
        toast.error('An error occurred during upload.');
      } finally {
        setFormLoading(false);
      }
    },
    [],
  );

  const handleDicomPatchForm = useCallback(
    async (formValue: DicomPatch, id: number): Promise<Dicom | void> => {
      try {
        setUploadProgress(0);
        setFormLoading(true);
        const uploadedDicom = await updateDicom(formValue, id);
        toast.success('Update successful!');
        return uploadedDicom;
      } catch (error) {
        console.log(error);
        toast.error('An error occurred during update.');
      } finally {
        setFormLoading(false);
      }
    },
    [],
  );

  const extractDICOMData = (dataSet: DicomDataSet) => {
    const getDicomElement = (tag: string) => dataSet.string(tag) || undefined;

    const newData = {
      patientName: getDicomElement('x00100010') || '',
      patientEmail: '',
      patientDOB: getDicomElement('x00100030') || '',
      providerNpi: getDicomElement('x00101050') || '',
      providerName: '',
      referringPhysicianName: getDicomElement('x00080090') || '',
      performingPhysicianName: getDicomElement('x00081050') || '',
      physicianEmail: '',
      radiologistName: '',
      radiologistEmail: '',
      fileSetId: getDicomElement('x00041130') || '',
      imageId: getDicomElement('x00080018') || '',
      dicomStudyId: getDicomElement('x00200010') || '',
      dicomStudyDate: getDicomElement('x00080020') || '',
      dicomStudyDescription: getDicomElement('x00081030') || '',
      dicomAccessionId: getDicomElement('x00080050') || '',
      dicomSeriesId: getDicomElement('x0020000e') || '',
      dicomSeriesModality: getDicomElement('x00080060') || '',
      dicomSeriesDescription: getDicomElement('x0008103e') || '',
      dicomSeriesNumber: getDicomElement('x00200011') || '',
      dicomBodyPartExamined: getDicomElement('x00180015') || '',
      dicomSOPInstanceId: getDicomElement('x00080018') || '',
      dicomPatientName: getDicomElement('x00100010') || '',
      dicomDOB: getDicomElement('x21000040') || '',
    };

    setDicomData(newData);
  };

  const processDICOMFile = useCallback((byteArray: Uint8Array) => {
    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      extractDICOMData(dataSet);
    } catch (error) {
      console.error('Eroare la parsarea fișierului DICOM', error);
    }
  }, []);

  useEffect(() => {
    setDicomData(undefined);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target && e.target.result) {
          processDICOMFile(new Uint8Array(e.target.result as ArrayBuffer));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file, processDICOMFile]);

  return {
    fetchLoading,
    formLoading,
    handleDicomForm,
    uploadProgress,
    handleDicomPatchForm,
    dicomData,
    setFile,
    file,
    fetchUserDicoms,
    userDicoms,
    fetchDicomById,
    pinDicomToIpfs,
    mintDicomNft,
  };
};

export default useDicom;
