import React, { ReactElement, useEffect } from 'react';
import DicomCardsList from '../components/DicomCardsList/DicomCardsList';
import Section from '../components/Section/Section';
import RecentActivities from '../components/RecentActivities/RecentActivities';
import { ActivityInterface } from '../shared/models/activity';
import useDicom from '../shared/hooks/useDicom';
import DicomCardsListLoading from '../components/DicomCardsList/DicomCardsListLoading';

const MyMedicalRecordsPage = (): ReactElement => {
  const activities: ActivityInterface[] = [
    {
      id: 28734687,
      email: 'larry.king@gmail.com',
      studyName: 'Lung CT',
      date: '10/08/2023',
      type: 'share',
      name: 'Larry King',
      permissions: 'view',
    },
    {
      id: 9487598,
      email: 'dr.john.doe@institution.com',
      studyName: 'Lung CT',
      date: '24/05/2022',
      type: 'release',
      name: 'Dr. John Doe',
      permissions: 'view + share',
    },
  ];

  const { userDicoms, fetchUserDicoms, fetchLoading } = useDicom();

  useEffect(() => {
    fetchUserDicoms({});
  }, [fetchUserDicoms]);

  return (
    <>
      <Section>
        {userDicoms && !fetchLoading && <DicomCardsList patientView dicomFiles={userDicoms} />}
        {fetchLoading && <DicomCardsListLoading patientView />}
      </Section>
      {!fetchLoading && userDicoms?.length === 0 && 'No studies found'}
      <Section title='Recent activities'>
        <RecentActivities events={activities} />
      </Section>
    </>
  );
};

export default MyMedicalRecordsPage;
