const messages = {
  errorOccured: 'An error occurred',
  invalidShare: 'Invalid share',
  alreadySharedToUser: 'Already shared to this user',
  revokedSucces: 'Revoked successfully',
  sharedSuccess: 'Shared successfuly!',
  userNotFound: 'Account does not exist',
  requiredField: 'This field is mandatory',
  invalidEmail: 'Invalid email',
  invalidFiletype: 'Invalid file type. Only .dcm files are allowed.',
  maxFileSize: 'File size exceeds the limit of 40MB.',
  dicomDownloadError: 'Dicom download error',
  dicomImageDicoderInitializeFailed: 'Failed to initialize Dicom viewer',
};

export default messages;
