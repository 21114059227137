import React from 'react';
import { ErrorMessage, Field } from 'formik';
import cx from 'classnames';
import styles from './InputField.module.css';

type InputFieldProps = {
  placeholder?: string;
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  options?: string[];
};

const InputField = ({ placeholder, name, label, type, required, options }: InputFieldProps) => {
  const renderInput = () => {
    if (type === 'select' && options) {
      return (
        <Field as='select' name={name} defaultValue={''} className={styles.input}>
          <option value='' disabled>
            Choose one
          </option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Field>
      );
    } else {
      return <Field type={type} name={name} placeholder={placeholder} className={styles.input} />;
    }
  };

  return (
    <div className={cx(styles.root)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      {renderInput()}
      <ErrorMessage name={name} render={(msg) => <div className={styles.error}>{msg}</div>} />
    </div>
  );
};

export default InputField;
