import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as dicomParser from 'dicom-parser';
import { dicomTags } from '../staticText/rawDicomLabelTags';

interface useDicomParserViewerHook {
  loading: boolean;
  dicomData: DicomTypeValue[] | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
}

interface DicomDataSet {
  string: (tag: string) => string | undefined;
}

type DicomTypeValue = {
  name: string;
  value?: string;
};

const useDicomParserViewer = (): useDicomParserViewerHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dicomData, setDicomData] = useState<DicomTypeValue[]>();
  const [file, setFile] = useState<File | null>(null);

  const extractDICOMData = (dataSet: DicomDataSet) => {
    const allData: DicomTypeValue[] = [];
    for (const tag in dicomTags) {
      const value = dataSet.string(tag);
      if (value && value !== '' && value !== '®') {
        allData.push({ name: dicomTags[tag], value });
      }
    }

    setDicomData(allData);
  };

  const processDICOMFile = useCallback((byteArray: Uint8Array) => {
    try {
      setLoading(true);
      const dataSet = dicomParser.parseDicom(byteArray);
      extractDICOMData(dataSet);
    } catch (error) {
      toast.error('Parse error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setDicomData(undefined);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target && e.target.result) {
          processDICOMFile(new Uint8Array(e.target.result as ArrayBuffer));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file, processDICOMFile]);

  return {
    loading,
    setFile,
    file,
    dicomData,
  };
};

export default useDicomParserViewer;
