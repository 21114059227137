import React, { ReactElement } from 'react';
import styles from './ActivityLine.module.css';
import { ActivityInterface } from '../../shared/models/activity';
import Tag from '../Tag/Tag';

const ActivityLine = ({
  event,
  bottomHr,
}: {
  event: ActivityInterface;
  key: string | number;
  bottomHr: boolean;
}): ReactElement => {
  const getTextLine = (): ReactElement => {
    if (event.type === 'share') {
      return (
        <>
          You <span>shared</span> {event.studyName} ({event.date}) <span>with</span> {event.name}
        </>
      );
    }
    return (
      <>
        {event.name} <span>released your</span> {event.studyName} ({event.date})
      </>
    );
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.description}>{getTextLine()}</div>
        <div>{event.email}</div>
        <div className={styles.permissions}>{event.permissions}</div>
        <div className={styles.type}>
          <Tag type={event.type} />
        </div>
      </div>
      {bottomHr && <hr className={styles.separator} />}
    </>
  );
};

export default ActivityLine;
