import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
// import { ReactComponent as LockIcon } from '../../assets/lockIcon.svg';
import styles from './DicomCard.module.css';
import Button from '../Button/Button';
import { Dicom } from '../../shared/models/dicom';
import config from '../../config';
import ShareStudy from '../ShareStudy/ShareStudy';
import calculateAge from '../../shared/utils/calculateAge';
// import Tag from '../Tag/Tag';

const DicomCard = ({
  dicom,
  patientView,
}: {
  dicom: Dicom;
  key: string | number;
  patientView?: boolean;
}): ReactElement => {
  const infoRows: { label: string; value: string }[] = [
    ...(dicom?.data?.dicomStudyDate
      ? [{ label: 'Taken on', value: dicom.data.dicomStudyDate }]
      : []),
    ...(dicom.data?.providerName ? [{ label: 'Taken in', value: dicom.data?.providerName }] : []),
    ...(dicom?.data?.performingPhysicianName
      ? [{ label: 'Taken by', value: dicom.data.performingPhysicianName }]
      : []),
  ];

  const patientInfoRows: { label: string; value: string }[] = [
    ...(dicom?.data?.patientName ? [{ label: 'Patient', value: dicom.data.patientName }] : []),
    ...(dicom.data?.patientGender ? [{ label: 'Gender', value: dicom.data?.patientGender }] : []),
    ...(dicom.data?.patientDOB
      ? [{ label: 'Age', value: `${calculateAge(dicom.data?.patientDOB)}` }]
      : []),
    ...(dicom?.data?.patientDOB ? [{ label: 'DOB', value: dicom.data.patientDOB }] : []),
  ];

  return (
    <div
      className={cx(
        styles.root,
        // dicom.locked && styles.locked
      )}
    >
      {/* {dicom.newTag && (
        <div className={styles.newTag}>
          <Tag type='new' />
        </div>
      )} */}
      <div className={styles.name}>
        <div className={styles.image}>
          {/* {dicom.locked && <LockIcon width={27} height={'100%'} />} */}
          {/* {!dicom.locked && */}
          <BrandMark width={38} height={'100%'} />
          {/* } */}
        </div>
        <h2>{dicom.data?.dicomStudyDescription}</h2>
      </div>
      {!patientView && patientInfoRows.length > 0 && (
        <div className={styles.infoRows}>
          {patientInfoRows.map((item) => (
            <div className={styles.infoRow} key={item.label}>
              <div className={styles.label}>{item.label}:</div>
              <div className={styles.value}>{item.value}</div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.infoRows}>
        {infoRows.map((item) => (
          <div className={styles.infoRow} key={item.label}>
            <div className={styles.label}>{item.label}:</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        ))}
      </div>
      <hr className={cx(styles.separator, styles.absolute)} />
      <div className={cx(styles.buttons, styles.absolute)}>
        {/* {dicom.locked && (
          <Button fullWidth disabled style='naked'>
            Locked
          </Button>
        )}
        {!dicom.locked && (
          <> */}
        <Button fullWidth toPage={config.routes.studyId.replace(':id', String(dicom.id))}>
          View
        </Button>
        {patientView && (
          <Button
            fullWidth
            style='naked'
            modal={{ title: 'Share study', contents: <ShareStudy id={dicom.id} /> }}
          >
            Share
          </Button>
        )}
        {/* </>
       )} */}
      </div>
    </div>
  );
};

export default DicomCard;
