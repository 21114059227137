import axios, { AxiosInstance } from 'axios';
import config from '../config';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.api.baseUrl,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const localStorageGeneralToken = localStorage.getItem('jwtToken');
    config.headers.set('Authorization', `Bearer ${localStorageGeneralToken}`);
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return await Promise.reject(error);
  },
);
