import React, { ReactElement } from 'react';
import styles from './DicomCardsList.module.css';
import DicomCardLoading from '../DicomCard/DicomCardLoading';

const DicomCardsListLoading = ({ patientView }: { patientView?: boolean }): ReactElement => {
  return (
    <div className={styles.root}>
      <DicomCardLoading patientView={patientView} />
    </div>
  );
};

export default DicomCardsListLoading;
