import React, { ReactElement, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import styles from './ShareStudy.module.css';
import Button from '../Button/Button';
import InputField from '../Form/InputField';
import { revokesShareStudy, shareStudy } from '../../shared/services/dicom.service';
import { ShareError } from '../../shared/enum/shareErrors';
import messages from '../../shared/staticText/messages';

type ShareStudyProps = {
  id: number;
  isRevoke?: boolean;
};

type ShareFormValues = {
  email: string;
};

const ShareStudy = ({ id, isRevoke }: ShareStudyProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (
    values: ShareFormValues,
    { resetForm }: FormikHelpers<ShareFormValues>,
  ) => {
    try {
      setLoading(true);
      if (isRevoke) {
        await revokesShareStudy(String(id), values.email);
      } else {
        await shareStudy(String(id), values.email);
      }

      toast.success(isRevoke ? messages.revokedSucces : messages.sharedSuccess);
      resetForm();
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === ShareError.ALREADY_SHARED) {
          toast.error(messages.alreadySharedToUser);
        } else if (error.response.data.message === ShareError.INVALID_SHARE) {
          toast.error(messages.invalidShare);
        } else if (error.response.data.message === ShareError.USER_NOT_FOUND) {
          toast.error(messages.userNotFound);
        } else {
          toast.error(messages.errorOccured);
        }
      } else {
        toast.error(messages.errorOccured);
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('This field is mandatory'),
  });

  return (
    <div className={styles.root}>
      <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form className={styles.form}>
          <InputField
            name='email'
            type='text'
            label={isRevoke ? 'Revoke for' : 'Share with'}
            required
          />
          <Button type='submit' style='navy' loading={loading} disabled={loading}>
            {isRevoke ? 'Revoke' : 'Share'}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default ShareStudy;
