export type ConfigType = {
  routes: {
    home: string;
    signIn: string;
    recoverPassword: string;
    myMedicalRecords: string;
    shared: string;
    release: string;
    studyId: string;
    patientsRecords: string;
  };
  api: {
    baseUrl: string;
    loginPath: string;
    dicom: string;
    dicomId: string;
    dicomIdIpfs: string;
    dicomIdMint: string;
    dicomIdShare: string;
    dicomIdRevoke: string;
    dicomIdDownload: string;
    dicomSharedWithMe: string;
  };
};

const config: ConfigType = {
  routes: {
    home: '/',
    signIn: '/signin',
    recoverPassword: '/recover-password',
    myMedicalRecords: '/my-medical-records',
    shared: '/shared',
    release: '/release',
    studyId: '/study/:id',
    patientsRecords: '/patients-records',
  },
  api: {
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    loginPath: '/auth/login',
    dicom: '/dicom',
    dicomId: '/dicom/:id',
    dicomIdIpfs: '/dicom/:id/ipfs',
    dicomIdMint: '/dicom/:id/mint',
    dicomIdShare: '/dicom/:id/share',
    dicomIdRevoke: '/dicom/:id/revoke',
    dicomIdDownload: '/dicom/:id/download',
    dicomSharedWithMe: '/dicom/shared-with-me',
  },
};

export default config;
