import React, { ReactElement, ReactNode, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './Button.module.css';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import Modal from '../Modal/Modal';

type ButtonProps = {
  children: ReactNode;
  style?: 'navy' | 'primary' | 'naked';
  fullWidth?: boolean;
  size?: 'large' | 'small';
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  icon?: ReactElement;
  onClick?: () => void;
  loading?: boolean;
  toPage?: string;
  modal?: {
    title: string;
    contents: ReactElement;
  };
  loadingGhost?: boolean;
};

const Button = ({
  children,
  style,
  fullWidth,
  size,
  disabled,
  type,
  icon,
  onClick,
  loading,
  toPage,
  modal,
  loadingGhost,
}: ButtonProps): ReactElement => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const goToPage = () => {
    if (toPage) navigate(toPage);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <button
        className={cx(
          styles.root,
          fullWidth && styles.fullWidth,
          style === 'naked' && styles.naked,
          style === 'navy' && styles.navy,
          size === 'large' && styles.large,
          icon && styles.withIcon,
          loading && styles.loading,
          loadingGhost && styles.loadingGhost,
        )}
        disabled={disabled}
        type={type}
        onClick={modal ? toggleModal : toPage ? goToPage : onClick}
      >
        {icon && !loading && <div className={styles.icon}>{icon}</div>}
        <span>
          {loading ? (
            <LoadingAnimation size={15} white={style === 'navy' && !disabled} />
          ) : (
            children
          )}
        </span>
      </button>
      {showModal && modal && (
        <Modal toggleView={toggleModal} title={modal?.title}>
          {modal?.contents}
        </Modal>
      )}
    </>
  );
};

export default Button;
