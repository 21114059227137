const convertDicomStringDate = (dateString: string, forHtmlDateInput?: boolean): Date | string => {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10);
  const day = parseInt(dateString.substring(6, 8), 10);

  if (forHtmlDateInput) return `${year}-${month}-${day}`;
  return new Date(year, month, day);
};

export default convertDicomStringDate;
