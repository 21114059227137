import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import config from '../config';
import NotFound from './404/notFound';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import MyMedicalRecordsPage from './myMedicalRecords';
import SharedWithMePage from './sharedWithMe';
import ReleaseRecordPage from './releaseRecord/releaseRecord';
import Section from '../components/Section/Section';
import { UserRoles } from '../shared/enum/userRoles';
import AuthPageWrapper from './login/AuthPageWrapper';
import SignIn from './login/SignIn';
import StudyPage from './study/[id]';

const Pages = (): ReactElement => {
  return (
    <Routes>
      <Route
        path={config.routes.signIn}
        element={
          <AuthPageWrapper>
            <SignIn />
          </AuthPageWrapper>
        }
      />
      <Route
        path={config.routes.home}
        element={
          <PageWrapper>
            <Section>Home!</Section>
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.myMedicalRecords}
        element={
          <PageWrapper roleAllowed={UserRoles.PATIENT}>
            <MyMedicalRecordsPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.shared}
        element={
          <PageWrapper>
            <SharedWithMePage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.release}
        element={
          <PageWrapper noRows roleAllowed={UserRoles.PROVIDER}>
            <ReleaseRecordPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.patientsRecords}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <SharedWithMePage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.studyId}
        element={
          <PageWrapper>
            <StudyPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.recoverPassword}
        element={
          <PageWrapper noValidation>
            <p>Recover Password!</p>
          </PageWrapper>
        }
      />
      <Route
        path='*'
        element={
          <PageWrapper>
            <NotFound />
          </PageWrapper>
        }
      />
    </Routes>
  );
};

export default Pages;
