import axios from 'axios';
import { LoginType } from '../models/authType';

import config from '../../config';

export const login = ({ email, password }: LoginType) => {
  return axios.post(`${config.api.baseUrl}${config.api.loginPath}`, {
    email,
    password,
  });
};
